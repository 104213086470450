export const GoogleReviewElfsight = (Component) => {
    // This part of the code is only run once when creating the component
    // You usually do not need anything here.

    return (
        <>
            <script
                src="https://static.elfsight.com/platform/platform.js"
                async
            ></script>
            <div
                class="elfsight-app-ff1725d5-6879-4708-93b2-0cf7eb1d791d"
                data-elfsight-app-lazy
            ></div>
        </>
    )
}
